import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { AuthProvider } from "./contexts/AuthContext";
import { GlobalStyles } from './theme/GlobalStyles';
import GlobalFonts from './fonts/fonts';
import { useTheme } from './theme/useTheme';

import Signup from "./containers/Signup";
import LoginPage from "./containers/LoginPage";
import DashboardPage from "./containers/DashboardPage";
import NewContactPage from "./containers/NewContactPage";
import ContactPage from "./containers/ContactPage";
import ContactBasicInfoPage from "./containers/ContactBasicInfoPage";
import ContactAddressPage from "./containers/ContactAddressPage";
import ContactUtilityBillsPage from "./containers/ContactUtilityBillsPage";
import ContactAppointmentPage from "./containers/ContactAppointmentPage";
import ContactUsagePage from "./containers/ContactUsagePage";
import ContactsPage from "./containers/ContactsPage";
import ForgotPasswordPage from "./containers/ForgotPasswordPage";
import LandingPage from "./containers/LandingPage";
import PrivateRoute from "./components/PrivateRoute";
function App() {
  const { theme } = useTheme();
  return (
    <>
    <ThemeProvider theme={theme}>
      <GlobalFonts />
      <GlobalStyles />
        <Router>
          <AuthProvider>
            <Routes>
              <Route exact path='/' element={<LandingPage />} />
              <Route exact path='/dashboard' element={<PrivateRoute />}>
                <Route exact path='/dashboard' element={<DashboardPage />} />
              </Route>
              <Route exact path='/contacts/new' element={<PrivateRoute />}>
                <Route exact path='/contacts/new' element={<NewContactPage />} />
              </Route>
              <Route exact path='/contacts/:contactId' element={<PrivateRoute />}>
                <Route exact path='/contacts/:contactId' element={<ContactPage />} />
              </Route>
              <Route exact path='/contacts/:contactId/basic-info' element={<PrivateRoute />}>
                <Route exact path='/contacts/:contactId/basic-info' element={<ContactBasicInfoPage />} />
              </Route>
              <Route exact path='/contacts/:contactId/address' element={<PrivateRoute />}>
                <Route exact path='/contacts/:contactId/address' element={<ContactAddressPage />} />
              </Route>
              <Route exact path='/contacts/:contactId/utility-bills' element={<PrivateRoute />}>
                <Route exact path='/contacts/:contactId/utility-bills' element={<ContactUtilityBillsPage />} />
              </Route>
              <Route exact path='/contacts/:contactId/usage' element={<PrivateRoute />}>
                <Route exact path='/contacts/:contactId/usage' element={<ContactUsagePage />} />
              </Route>
              <Route exact path='/contacts/:contactId/appointment' element={<PrivateRoute />}>
                <Route exact path='/contacts/:contactId/appointment' element={<ContactAppointmentPage />} />
              </Route>
              <Route exact path='/contacts' element={<PrivateRoute />}>
                <Route exact path='/contacts' element={<ContactsPage />} />
              </Route>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
      </>
  );
}

export default App;
