import React from 'react'

export default function IconInCircle(props) {
  let classes = "circle-icon-container flex-center";
  if(!!props.className) {
    classes += ` ${props.className}`
  }
  return (
    <div className={classes}>
        {props.children}
    </div>
  )
}
