import React from 'react'

export default function StyledInput(props) {
    const {
        label,
        placeholder,
        name,
        id,
        value,
        onChange,
        mb,
        units,
        type
    } = props;
    let classes = "";
    if(!!mb) {
        classes += " mb-1"
    }
    let classesInput = "styled-input";
    let showUnit = false;
    if(!!units) {
        if(!!value) {
            classesInput += " pr-4-5"
            showUnit = true;
            console.log("show unit");
        }
    }
    return (
        <div className={classes} style={{width:'100%',display:'flex',alignItems:"space-between"}}>
            <div className="styled-input w-30 flex-start text-start"><p>{label}<span className="color-4">*</span></p></div>
            <input
                style={{flexBasis:'70%'}}
                className={classesInput}
                placeholder={placeholder}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                type={type || "text"}
            />
            {showUnit && <span className="ml-neg-4 flex-center">{units}</span>}
        </div>
  )
}
