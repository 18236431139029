import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import ContainerWithNavbar from './ContainerWithNavbar';
import { useAuth } from '../contexts/AuthContext';
import PersonIcon from '@mui/icons-material/Person';
import IconInCircle from '../components/IconInCircle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { getSupportEmailFromEnvironment } from '../services/environment';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactPage() {
  const params = useParams();
  const contactId = !!params && params.contactId;
  const initialState = {
    isLoading: true,
    error: '',
    statistics: {}
  }
  const [state, setState] = useState(initialState);
  const { currentUser, userMetadata } = useAuth();
  const userFirstName = !!userMetadata && userMetadata.firstName || '';
  const navigate = useNavigate();
  const supportEmail = getSupportEmailFromEnvironment();
  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/statistics`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            setState({
              ...state,
              isLoading: false,
              error: '',
              statistics: res.data.statistics
            });
            console.log(res.data);
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  },[contactId]);

  function handleRedirect(e, route) {
    e.preventDefault();
    navigate(route);
  }

  const {
    error,
    isLoading,
    statistics
  } = state;

  let contactsSubmittedClasses = 'background-4 card text-start flex-vertical align-items-start'
  let jobsCompletedClasses = 'card text-start flex-vertical align-items-start';
  let contactsCount = 0;
  let lastContactSubmittedOn = '';
  let jobsCount = 0;
  let lastJobCompletedOn = '';
  let totalDollarsPaid = 0;
  let inactiveContactDollarValue = 0;

  if(!!statistics) {
    if(!!statistics.contactsCount) {
      contactsCount = statistics.contactsCount;
    }
    if(!!statistics.lastContactSubmittedOn) {
      lastContactSubmittedOn = statistics.lastContactSubmittedOn;
    }
    if(!!statistics.jobsCount) {
      jobsCount = statistics.jobsCount;
    }
    if(!!statistics.lastJobCompletedOn) {
      lastJobCompletedOn = statistics.lastJobCompletedOn;
    }
    if(!!statistics.totalDollarsPaid) {
      totalDollarsPaid = statistics.totalDollarsPaid;
    }
    if(!!statistics.inactiveContactDollarValue) {
      inactiveContactDollarValue = statistics.inactiveContactDollarValue;
    }
  }

  if(!lastContactSubmittedOn) {
    contactsSubmittedClasses += " justify-start";
  }

  if(!lastJobCompletedOn) {
    jobsCompletedClasses += " justify-start";
  }


  if(error) {
    return (
      <ContainerWithNavbar>
          <p>We're sorry, there has been an error.  Please reach out to support.</p>
      </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar>
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (!!statistics && !!contactsCount) {
    return (
      <ContainerWithNavbar>
      <div className="flex-start p-1">
          <IconInCircle className="flex-grow-0 mr-1">
              <PersonIcon fontSize='large' />
          </IconInCircle>
          {!userFirstName && <p>Welcome back!</p>}
          {!!userFirstName && <p>Welcome back, {userFirstName}!</p>}
      </div>
        <div className='p-1 ubuntu'>
          <div className='grid-2-col w-100'>
            <div className={contactsSubmittedClasses}>
              <p className="mb-1 fw-light text-small">Contacts Submitted</p>
              <p className="f-3">{contactsCount}</p>
              {lastContactSubmittedOn && (
                <div>
                  <p className='fw-light text-small'>Last Submitted On</p>
                  <p className='fw-light text-small mb-0'>{lastContactSubmittedOn}</p>
                </div>
              )}
            </div>
            <div className={jobsCompletedClasses}>
              <p className="mb-1 fw-light text-small">Jobs Completed</p>
              <p className="f-3">{jobsCount}</p>
              {lastJobCompletedOn && (
                <div>
                  <p className='fw-light text-small'>Last Completed On</p>
                  <p className='fw-light text-small mb-0'>{lastJobCompletedOn}</p>
                </div>
              )}
            </div>
            <div className="card grid-col-span-2 text-start fw-light background-5">
                <div className="flex-between align-items-start mb-2">
                  <p className="fw-light text-small">Finance</p>
                  <div>
                    <p className='f-3 bold text-end'>${totalDollarsPaid ? totalDollarsPaid : '0'}</p>
                    <p>Total Paid</p>
                  </div>
                </div>
                {inactiveContactDollarValue ? <>
                  {/* todo - $0 placeholder */}
                  <p className="fw-light text-medium">${0} in inactive customers</p>
                  {/* <p className="fw-light text-medium">${inactiveContactDollarValue} in inactive customers</p> */}
                  {/* <p className="fw-light text-medium"><a className="underline" href={`mailto:${supportEmail}`}>Help us move them forward</a></p> */}
                </> : <></>}
                
            </div>
          </div>
        </div>
        <p className='fw-light button-disabled m-1'>v 1.0</p>
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar text="Home">
        <p className="mt-4">You currently don’t have any deals submitted. Why don’t you go ahead and submit one now?</p>
        <button className="mt-4" onClick={e => handleRedirect(e,'/contacts/new')}><PersonAddAlt1Icon fontSize='large' /></button>
      </ContainerWithNavbar>
    )
  }
}
