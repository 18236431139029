import React from 'react';
import ExclamationIcon from "../icons/circle-exclamation-solid.svg";
import CheckIcon from "../icons/circle-check-solid.svg";

export default function NoticeCard(props) {
  const {
    type,
    text
  } = props;
  let classes = "relative mt-1 mb-1 black";
  let icon = ExclamationIcon;
  if(!!type) {
    if(type === "warning") {
      classes += " warning-card";
      icon = ExclamationIcon;
    } else if (type === "success") {
      classes += " success-card"
      icon = CheckIcon
    }
  }

  return (
    <div className={classes}>
      <img className="upper-right icon-small" src={icon} alt="notice" />
      {text}
    </div>
  );

}
