import React from 'react';
import { SpinnerCircular } from 'spinners-react';

const ConditionalWrapper = ({ shouldWrapChildren, children }) => {
  if(shouldWrapChildren) {
    return (
        <div className='flex-vertical-center fit-screen'>
            {children}
        </div>
    )
  } else {
    return children;
  }
}

export default function LoadingIcon(props) {
    const {
        fitScreen
    } = props;

    // to do - possibly control this by the theme
    let color = "#EEA630";

    return (
        <ConditionalWrapper
            shouldWrapChildren={fitScreen}
            children={<SpinnerCircular color={color} style={{margin: "0 auto!important"}} />}
        />
    )
}
