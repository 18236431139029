function getZipFromGoogleMapsAddress(components) {
    if(!!components && components.length > 0) {
        components.forEach(item => {
            if(!!item && !!item.types && item.types.length > 0) {
                if(item.types.includes["postal_code"]) {
                    return item.long_name || item.short_name;
                }
            }
        })
    }
    return '';
}

module.exports = {
    getZipFromGoogleMapsAddress
}
