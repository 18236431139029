export const getCountTruthyValuesInArray = (array) => {
    let count = 0;
    if(!!array && array.length > 0) {
        array.forEach(item => {
            if(!!item && item != 0) {
                count++;
            }
        })
    }
    return count;
}

export const updateMatchingRecordInArray = (array, name, value, update) => {
    const newArray = [];
    if(!!array && array.length > 0) {
        array.forEach(item => {
            if(!!item[name] && item[name] === value) {
              newArray.push({
                ...item,
                ...update
              })
            } else {
              newArray.push({...item})
            }
          });
    }
    return newArray;
}
