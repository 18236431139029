import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getImageFromEnvironment, getSupportEmailFromEnvironment } from '../services/environment';
import ContainerVerticalFullScreen from './ContainerVerticalFullScreen';

export default function LandingPage() {
  const navigate = useNavigate();

  function handleRedirect(e, route) {
    !!e && e.preventDefault();
    navigate(route);
  }

  const image = getImageFromEnvironment();
  const supportEmail = getSupportEmailFromEnvironment();
  
  return (
    <ContainerVerticalFullScreen padding>
        <div>
            {image && <img className='mt-4' styles={{display:'block'}} src={image} alt="main title image" />}
        </div>
        <div>
            <button className='mt-4' styles={{display:'block'}} onClick={e => handleRedirect(e, '/login')}>LOGIN</button>
            <p className="mt-4">Need help? Contact us at</p>
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </div>
    </ContainerVerticalFullScreen>
  )
}
