import { createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${({ theme }) => theme.colors.color_1};
    color: ${({ theme }) => theme.colors.color_2};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
    height: 100%;
  }

  h2 {
    color: ${({ theme }) => theme.colors.color_5};
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 3.9rem;
  }

  a {
    color: ${({ theme }) => theme.colors.color_4};
    text-decoration: none;
    cursor: pointer;
  }

  .underline, a {
    text-decoration: underline;
  }

  button {
    border: 0;
    display: inline-block;
    padding: 6px 72px;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    border-radius: 8px;
    cursor: pointer!important;
    background-color: ${({ theme }) => theme.colors.color_4};
    color: ${({ theme }) => theme.colors.color_2};
    font-family: ${({ theme }) => theme.font};
  }

  button.btn {
    background-color: ${({ theme }) => theme.colors.color_4};
    color: ${({ theme }) => theme.colors.color_2};
  }

  .button-variant-no-background {
    cursor: pointer;
    padding: 0px!important;
    background: none;
    border: none;
    border-radius: 0px;
    color: white;
  }

  .button-variant-1 {
    cursor: pointer;
    padding: 0px!important;
    background: ${({ theme }) => theme.colors.color_10};
    border: none;
    border-radius: 0px;
    color: white;
  }

  .button-variant-1 p {
    font-size: 12px;
    letter-spacing: 0px;
  }

  .button-variant-1-disabled {
    color: ${({ theme }) => theme.colors.color_11};
  }

  .button-variant-1-disabled svg {
    fill: ${({ theme }) => theme.colors.color_11};
  }

  .button-variant-2 {
    color: ${({ theme }) => theme.colors.color_12};
    background: ${({ theme }) => theme.colors.color_10};
    cursor: pointer;
    padding: 0px!important;
    border: none;
    border-radius: 0px;
    color: white;
  }

  .button-disabled {
    color: ${({ theme }) => theme.colors.color_11}!important;
    cursor: default!important;
  }

  .background-1 {
    background-color: ${({ theme }) => theme.colors.color_6};
  }

  .background-1.btn {
    background-color: ${({ theme }) => theme.colors.color_6};
  }

  .background-2 {
    background: ${({ theme }) => theme.colors.color_10}!important;
    background-color: ${({ theme }) => theme.colors.color_10}!important;
  }

  .background-3 {
    background: ${({ theme }) => theme.colors.color_15}!important;
    background-color: ${({ theme }) => theme.colors.color_15}!important;
  }

  .background-4 {
    background: ${({ theme }) => theme.colors.color_11}!important;
    background-color: ${({ theme }) => theme.colors.color_11}!important;
  }

  .background-5 {
    background: ${({ theme }) => theme.colors.color_16}!important;
    background-color: ${({ theme }) => theme.colors.color_16}!important;
  }

  .text-small {
    font-size: 0.8rem;
  }

  .text-medium {
    font-size: 0.9rem;
  }


  .text-1 {
    color: ${({ theme }) => theme.colors.color_4};
    font-weight: bold;
  }

  .text-2 {
    font-size: 1rem;
    letter-spacing: 0px;
    font-weight: 100;
    font-family: 'ubuntu';
  }

  .text-2-responsive {
    font-size: 1rem;
    letter-spacing: 0px;
    font-weight: 100;
    font-family: 'ubuntu';
  }

  .text-3-responsive {
    font-size: 1.3rem;
    letter-spacing: 0px;
    font-weight: 100;
    font-family: 'ubuntu';
  }

  .color-4 {
    color: ${({ theme }) => theme.colors.color_4};
  }

  .color-8 {
    color: ${({ theme }) => theme.colors.color_8};
  }

  .color-10 {
    color: ${({ theme }) => theme.colors.color_10};
  }

  .color-11 {
    color: ${({ theme }) => theme.colors.color_11};
  }

  .color-12 {
    color: ${({ theme }) => theme.colors.color_12};
  }

  .color-13 {
    color: ${({ theme }) => theme.colors.color_13};
  }

  button img {
    display: block;
    width: 36px;
    height: 36px;
    z-index:1000;
  }

  .dark {
    color: ${({ theme }) => theme.colors.color_1};
    font-weight: bold;
  }

  .input-transparent {
    color: black;
    font-family: Armata;
    font-size: 1.5rem;
    width: 100%;
  }

  .input-transparent::placeholder {
    color: ${({ theme }) => theme.colors.color_3};
  }

  .warning-card {
    background: ${({ theme }) => theme.colors.color_7};
    border: 2px solid ${({ theme }) => theme.colors.color_4};
    padding: 25px 45px;
    border-radius: 10px;
  }

  .success-card {
    background: ${({ theme }) => theme.colors.color_9};
    border: 2px solid ${({ theme }) => theme.colors.color_8};
    padding: 25px 45px;
    border-radius: 10px;
  }

  .datepicker-override-styles input {
    min-width: 200px;
    background: none;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    padding: 6px;
  }
  
  .datepicker-override-styles input::placeholder {
    color: ${({ theme }) => theme.colors.color_11};
  }

  .link {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.color_12};
  }

  .circle-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 52px;
    flex-shrink: 0;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.color_14};
  }

  .circle-icon-container-large {
    height: 100px;
    width: 100px;
  }

  .circle-icon-container-large svg {
    font-size: 60px;
  }

  .card {
    width: 100%;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.color_15};
    padding: 24px;
    box-sizing: border-box;
  }

  .card p {
    margin-bottom: 4px;
  }

  @media (max-width:900px) {
    .text-2-responsive {
      font-size: 0.9rem;
    }
    .text-3-responsive {
      font-size: 0.9rem;
    }
  }
`;
