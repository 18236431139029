import React from 'react';
import CottageIcon from '@mui/icons-material/Cottage';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ContactsIcon from '@mui/icons-material/Contacts';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InsightsIcon from '@mui/icons-material/Insights';
import { useNavigate } from 'react-router-dom';

export default function NavbarBottom() {
    const navigate = useNavigate();
    function handleRedirect(e, route) {
        !!e && e.preventDefault();
        navigate(route);
    }
  return (
    <div className="w-100 fixed-bottom flex h-8 navbar">
        <button className="flex-vertical-center button-variant-1 ml-0 mr-0 m-0-svg-container" onClick={e => handleRedirect(e,"/dashboard")}>
            <CottageIcon />
            <p className="m-1-0">Home</p>
        </button>
        <button className="flex-vertical-center button-variant-1 ml-0 mr-0 m-0-svg-container" onClick={e => handleRedirect(e,"/contacts/new")}>
            <PersonAddAlt1Icon />
            <p className="m-1-0">Add New</p>
        </button>
        <button className="flex-vertical-center button-variant-1 ml-0 mr-0 m-0-svg-container" onClick={e => handleRedirect(e,"/contacts")}>
            <AssignmentIndIcon />
            <p className="m-1-0">Contacts</p>
        </button>
        <button className="flex-vertical-center button-variant-1 ml-0 mr-0 m-0-svg-container button-variant-1-disabled" disabled>
            <InsightsIcon />
            <p className="m-1-0">Insights</p>
        </button>
    </div>
  )
}
