import React from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CheckboxContainer from './CheckboxContainer';
import { getLabelWhoSetsTheAppointment } from '../services/environment';

export default function AppointmentInfo(props) {
    const {
        handleToggle,
        handleUpdateState,
        appointmentDateTime,
        isAppointmentSetInternally
    } = props;
    const labelWhoSetsTheAppointment = getLabelWhoSetsTheAppointment();

    return (
        <>
            <p className='mb-1 mt-3'>Select Date & Time For The Appointment</p>
            <div className="datepicker-override-styles">
                <ReactDatePicker
                    selected={appointmentDateTime}
                    onChange={(date) => handleUpdateState({appointmentDateTime: date})}
                    showTimeSelect
                    placeholderText={!isAppointmentSetInternally ? "August 2, 2023 12:00 AM" : ""}
                    disabled={isAppointmentSetInternally}
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
            </div>
            <p className='mt-2 bold'>OR</p>
            <CheckboxContainer
                label={labelWhoSetsTheAppointment}
                id="isAppointmentSetInternally"
                name="isAppointmentSetInternally"
                checked={isAppointmentSetInternally}
                handleToggle={handleToggle}
                center
            />
        </>
    )
}
