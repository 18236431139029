import React from 'react';

export default function CheckboxContainer(props) {
    const {
        name,
        id,
        label,
        checked,
        handleToggle,
        disabled,
        center
    } = props;
    let classes = "p-2";
    if(!!center) {
        classes += " flex-center";
    } else {
        classes += " flex-start";
    }
    if(!!disabled) {
        classes += " color-11"
    }
    return (
        <p className={classes}>
            <input
                disabled={disabled}
                type="checkbox"
                onChange={handleToggle}
                name={name}
                id={id}
                checked={checked}
            />
            <label className="ml-1" htmlFor={id}>{label}</label>
        </p>
    )
}
