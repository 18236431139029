import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ContainerWithNavbar from './ContainerWithNavbar';
import NavbarContacts from '../components/NavbarContacts';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ContactRow from '../components/ContactRow';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactsPage() {
  const initialState = {
    isLoading: true,
    error: '',
    contacts: []
  }
  const [state, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            setState({
              ...state,
              isLoading: false,
              error: '',
              contacts: res.data.contacts
            })
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  }, []);

  function handleRedirect(e,route) {
    e.preventDefault();
    if(!!route) {
      navigate(route);
    }
  }

  const {
    isLoading,
    error,
    contacts
  } = state;
  if(error) {
    return (
      <ContainerWithNavbar text="My Contacts">
        <h2>We're sorry.  It looks like there has been an error. Please reach out to support.  Thank you!</h2>
      </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar text="My Contacts">
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (!!contacts && contacts.length > 0) {
    return (
      <ContainerWithNavbar text="My Contacts">
        <NavbarContacts
          contacts={contacts}
        />
        {contacts.map((contact, index) => (
          <ContactRow
            key={index}
            contact={contact}
            handleRedirect={handleRedirect}
            mb
          />
        ))}
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar text="My Contacts">
        {/* <NavbarContacts
          contacts={contacts}
        /> */}
        <p className="mt-4">You currently don’t have any deals submitted. Why don’t you go ahead and submit one now?</p>
        <button className="mt-4" onClick={e => handleRedirect(e,'/contacts/new')}><PersonAddAlt1Icon fontSize='large' /></button>
      </ContainerWithNavbar>
    )
  }
}
