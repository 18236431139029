import { createGlobalStyle } from 'styled-components';

import ArmataRegularTtf from './Armata-Regular.ttf';
import UbuntuLightTtf from './Ubuntu-Light.ttf';
import UbuntuMediumTtf from './Ubuntu-Medium.ttf';
import UbuntuBoldTtf from './Ubuntu-Bold.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Armata';
        src: url(${ArmataRegularTtf}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Ubuntu';
        src: url(${UbuntuLightTtf}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Ubuntu';
        src: url(${UbuntuMediumTtf}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Ubuntu';
        src: url(${UbuntuBoldTtf}) format('truetype');
        font-weight: bold;
        font-style: normal;
    }
`;
