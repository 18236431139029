import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';

export default function NavbarContacts(props) {
    const {
        contacts
    } = props;
    let contactsLength = 0;
    if(!!contacts) {
        contactsLength = contacts.length;
    }
    return (
        <div className="w-100 flex-between background-2 p-3-2">
            <p className="text-3-responsive bold">All Contacts ({contactsLength})</p>
            {/* todo - enable hamburger button when sorting functionality is added */}
            {(false && !!contactsLength) && <button
                className="ubuntu button-variant-2 text-2 flex-center"
                //todo handle this button and menu
                onClick={(e) => {e.preventDefault();console.log("hamburger menu clicked")}}
            >
                <MenuIcon fontSize='large' />
            </button>}
        </div>
    )
}
