import React, { useState, useRef } from 'react';
import * as Sentry from "@sentry/react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import NoticeCard from '../components/NoticeCard';

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  // todo - remove loading?
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleRedirect (e, route) {
    e.preventDefault();
    navigate(route);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    try {
      setError('');
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch (error) {
      console.log(error);
      setError('Failed to create an account');
      Sentry.captureException(error);
    }
    setLoading(false);

  }

  return (
    <div>
      <h3>Sign Up</h3>
      {error && <NoticeCard text={error} type="warning" />}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email address</label>
          <input id="email" aria-describedby="email address" ref={emailRef} />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input id="password" type="password" aria-describedby="password" ref={passwordRef} />
        </div>
        <div>
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <input id="passwordConfirm" type="password" aria-describedby="confirm password" ref={passwordConfirmRef} />
        </div>
        <button type="submit">Sign Up</button>
      </form>
      <div>
        <p><a onClick={e => handleRedirect(e, '/forgot-password')}>Forgot password?</a></p>
        <p>Already have an account? <a onClick={e => handleRedirect(e, '/')}>Log in here</a></p>
      </div>
    </div>
  )
}
