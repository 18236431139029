import React from 'react';

export default function SimpleNavbar(props) {
    const {
        title,
        labelButtonLeft,
        enableButtonLeft,
        handleButtonLeft,
        labelButtonRight,
        enableButtonRight,
        handleButtonRight
    } = props;
    let classesButtonLeft = "ubuntu button-variant-2 text-2 color-12";
    if(!enableButtonLeft) {
        classesButtonLeft += " button-disabled";
    }
    let classesButtonRight = "button-variant-2 text-2 bold color-4";

    // todo - remove temporary fix to center content when no labelButtonRight exists
    if(!labelButtonRight) {
        classesButtonRight += " color-10";
    } else if(!enableButtonRight) {
        classesButtonRight += " button-disabled";
    }
    return (
        <div className="w-100 flex-between background-2 h-4 p-0-2">
            <button
                className={classesButtonLeft}
                onClick={handleButtonLeft}
                disabled={!enableButtonLeft}
            >
                {labelButtonLeft}
            </button>
            <p className="text-2 bold">{title}</p>
            <button
                className={classesButtonRight}
                onClick={handleButtonRight}
                disabled={!enableButtonRight}
            >
                {/* todo - remove temporary fix (including "Back" below) to center content when no labelButtonRight exists */}
                {labelButtonRight || "Back"}
                {/* {labelButtonRight} */}
            </button>
        </div>
    )
}
