import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { useNavigate } from 'react-router-dom';

import ContainerWithNavbar from './ContainerWithNavbar';
import NavbarNewContact from '../components/NavbarNewContact';
import GoogleMaps from '../components/GoogleMaps';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import NoticeCard from '../components/NoticeCard';
import UploadFiles from '../components/UploadFiles';


import Usage from '../components/Usage';
import CheckboxContainer from '../components/CheckboxContainer';
import { getCountTruthyValuesInArray, updateMatchingRecordInArray } from '../utils/arrays';
import BasicInfo from '../components/BasicInfo';
import AppointmentInfo from '../components/AppointmentInfo';
import LoadingIcon from '../components/LoadingIcon';

export default function NewContactPage() {
    const navigate = useNavigate();
    const initialState = {
        token: '',
        hasEdits: false,
        isLoading: true,
        error: '',
        page: 'Basic Info',
        // page: 'Appointment',
        googleMapsApiKey: '',
        labelNext: 'Next',
        labelBack: 'Cancel',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        lat: '',
        lng: '',
        zoom: '',
        bills: [],
        confirmNotProvidingUtilityBills: false,
        hasOnlyYearlyUsage: false,
        confirmHasNoUsage: false,
        yearlyUsage: '',
        januaryUsage: '',
        februaryUsage: '',
        marchUsage: '',
        aprilUsage: '',
        mayUsage: '',
        juneUsage: '',
        julyUsage: '',
        augustUsage: '',
        septemberUsage: '',
        octoberUsage: '',
        novemberUsage: '',
        decemberUsage: '',
        isAppointmentSetInternally: false,
        appointmentDateTime: ''
    }
    const [state, setState] = useState(initialState);
    const pages = ["Basic Info", "Address", "Utility Bill", "Usage", "Appointment"];
    const { currentUser } = useAuth();
    
    useEffect(() => {
        currentUser.getIdTokenResult()
        .then(tokenIdResult => {
            if(tokenIdResult) {
                setState({
                    ...state,
                    token: tokenIdResult.token
                });
                getGoogleMapsApiKey(tokenIdResult.token);
            }
        });
    }, []);

    async function getGoogleMapsApiKey(token) {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}/api/google-maps`;
            console.log({ endpoint, reactAppApiUrl: process.env.REACT_APP_API_URL });
            axios
            .get(endpoint, { headers: { Authorization: `Bearer ` + token } })
            .then(res => {
                if(!!res && !!res.data && res.data.googleMapsApiKey) {
                    setState({
                        ...state,
                        isLoading: false,
                        googleMapsApiKey: res.data.googleMapsApiKey
                    })
                } else {
                    throw Error("No googleMapsApiKey in response");
                }
            }).catch(error => {
                console.log(error);
                Sentry.captureException(error);
                setState({
                    ...state,
                    error,
                    isLoading: false
                });
            })
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
            setState({
                ...state,
                error,
                isLoading: false
            });
        }
    }

    function handleUpdateState(data) {
        if(!!data && typeof data === "object") {
            setState(prevState => ({
                ...prevState,
                ...data
            }));
        }
    }

    function handleToggle(e) {
        console.log({ name: e.target.name, value: e.target.value, checked: e.target.checked });
        if(e.target.name === "isAppointmentSetInternally" && !!e.target.checked) {
            setState({
                ...state,
                [e.target.name]: !state[e.target.name],
                appointmentDateTime: ''
            })
        } else if(e.target.name === "hasOnlyYearlyUsage" && !!e.target.checked){
            setState({
                ...state,
                [e.target.name]: !state[e.target.name],
                confirmHasNoUsage: false
            })
        } else {
            setState({
                ...state,
                [e.target.name]: !state[e.target.name]
            })
        }
    }

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
            hasEdits: true
        })
    }

    function handleNext() {
        const indexOfPage = pages.indexOf(page);
        if(indexOfPage > -1) {
            if(indexOfPage === pages.length - 1) {
                setState({
                    ...state,
                    isLoading: true
                });
                const dataToSend = {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    email: state.email,
                    phone: state.phone,
                    address: state.address,
                    lat: state.lat,
                    lng: state.lng,
                    confirmNotProvidingUtilityBills: state.confirmNotProvidingUtilityBills,
                    hasOnlyYearlyUsage: state.hasOnlyYearlyUsage,
                    yearlyUsage: state.yearlyUsage,
                    bills: state.bills,
                    confirmHasNoUsage: state.confirmHasNoUsage,
                    januaryUsage: state.januaryUsage,
                    februaryUsage: state.februaryUsage,
                    marchUsage: state.marchUsage,
                    aprilUsage: state.aprilUsage,
                    mayUsage: state.mayUsage,
                    juneUsage: state.juneUsage,
                    julyUsage: state.julyUsage,
                    augustUsage: state.augustUsage,
                    septemberUsage: state.septemberUsage,
                    octoberUsage: state.octoberUsage,
                    novemberUsage: state.novemberUsage,
                    decemberUsage: state.decemberUsage,
                    isAppointmentSetInternally: state.isAppointmentSetInternally,
                    appointmentDateTime: state.appointmentDateTime
                }
                currentUser.getIdTokenResult().then(tokenIdResult => {
                    const newToken = !!tokenIdResult && tokenIdResult.token;
                    return axios.post(`${process.env.REACT_APP_API_URL}/api/contacts`, dataToSend, {
                        headers: {
                          'Authorization': `Bearer ` + newToken
                        }
                    }).then(res => {
                        setState({
                            ...state,
                            isLoading: false
                        });
                        if(!!res && !!res.data && !!res.data.id) {
                            navigate(`/contacts/${res.data.id}`);
                        } else {
                            setState({
                                ...state,
                                error: true
                            })
                        }
                    }).catch(error => {
                        console.log(error);
                        Sentry.captureException(error);
                        setState({
                            ...state,
                            isLoading: false,
                            error
                        })
                    })
                });
            } else {
                setState({
                    ...state,
                    page: pages[indexOfPage + 1],
                    labelNext: (indexOfPage === pages.length - 2) ? 'Submit' : 'Next',
                    labelBack: 'Back'
                });
            }
        } else {
            Sentry.captureMessage(`Page ${page} not found on contacts/new container`);
        }
    }

    function handleBack() {
        const indexOfPage = pages.indexOf(page);
        if(indexOfPage > -1) {
            if(indexOfPage === 0) {
                navigate('/contacts');
            } else {
                setState({
                    ...state,
                    page: pages[indexOfPage - 1],
                    labelNext: 'Next',
                    labelBack: indexOfPage === 1 ? 'Cancel' : 'Back'
                });
            }
        } else {
            Sentry.captureMessage(`Page ${page} not found on contacts/new page`);
        }
    }

    const handleUploadBill = (file) => {
        if(!!file) {
          const temporaryId = file.temporaryId;
          setState({
            ...state,
            bills: [
              ...state.bills,
              file
            ],
            confirmNotProvidingUtilityBills: false
          });
    
          currentUser.getIdTokenResult().then(tokenIdResult => {
            const newToken = !!tokenIdResult && tokenIdResult.token;
    
            const formData = new FormData();
            formData.append("bill", file.file);
            return axios.post(`${process.env.REACT_APP_API_URL}/api/bills`, formData, {
              headers: {
                'Authorization': `Bearer ` + newToken,
                'Content-Type': 'multipart/form-data'
              }
            }).then(res => {
              if(!!res && !!res.data) {
                  setState(prevState => {
                    const bills = prevState.bills;
                    const update = {
                      isLoading: false
                    }
                    if(!!res.data.googleDriveId) {
                      update['googleDriveId'] = res.data.googleDriveId;
                    }
                    const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, update);
                    return ({
                      ...prevState,
                      bills: updatedBills
                    });
                  });
              } else {
                setState(prevState => {
                  const bills = prevState.bills;
                  const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
                  return ({
                    ...prevState,
                    bills: updatedBills
                  });
                })
              }
            }).catch(error => {
              console.log("error: ");
              console.log(error);
              Sentry.captureException(error);
              setState(prevState => {
                const bills = prevState.bills;
                const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
                return ({
                  ...prevState,
                  bills: updatedBills
                });
              })
            });
          });
        }
      }

    const {
        isLoading,
        error,
        googleMapsApiKey,
        page,
        labelNext,
        labelBack,
        firstName,
        lastName,
        email,
        phone,
        address,
        lat,
        lng,
        zoom,
        confirmNotProvidingUtilityBills,
        hasOnlyYearlyUsage,
        yearlyUsage,
        bills,
        confirmHasNoUsage,
        januaryUsage,
        februaryUsage,
        marchUsage,
        aprilUsage,
        mayUsage,
        juneUsage,
        julyUsage,
        augustUsage,
        septemberUsage,
        octoberUsage,
        novemberUsage,
        decemberUsage,
        isAppointmentSetInternally,
        appointmentDateTime
    } = state;

    let disabledNext = true;
    let disabledBack = false;

    if(page === "Basic Info") {
        if(!!firstName && !!lastName && !!email && !!phone) {
            disabledNext = false;
        }
    } else if (page === "Address") {
        if(!!address && !!lat && !!lng) {
            disabledNext = false;
        }
    } else if (page === "Utility Bill") {
        //todo might have to change this when we implement bill upload
        if(!!confirmNotProvidingUtilityBills || !!bills && bills.length > 0) {
            disabledNext = false;
        }
    } else if (page === "Usage") {
        const countTruthyUsageValues = getCountTruthyValuesInArray([januaryUsage, februaryUsage, marchUsage, aprilUsage, mayUsage, juneUsage, julyUsage, augustUsage, septemberUsage, octoberUsage, novemberUsage, decemberUsage]);
        if(!!confirmHasNoUsage || (hasOnlyYearlyUsage && !!yearlyUsage) || (!hasOnlyYearlyUsage && countTruthyUsageValues >=3)) {
            disabledNext = false;
        }
    } else if (page === "Appointment") {
        if(!!isAppointmentSetInternally || !!appointmentDateTime) {
            disabledNext = false;
        }
    }

    return (
        <ContainerWithNavbar text="New Contact">
            {(!error && !isLoading) && <NavbarNewContact
                title={page}
                handleNext={handleNext}
                handleBack={handleBack}
                disabledNext={disabledNext}
                disabledBack={disabledBack}
                labelNext={labelNext}
                labelBack={labelBack}
            />}
            {(isLoading && !error) && (<>
                <LoadingIcon />
            </>)}
            {(!isLoading && error) && (<>
                <div className="p-2">
                    <p>We're sorry, it looks like there was an error.  Please reach out to support for assistance.  Thank you!</p>
                </div>
            </>)}
            {(page === "Basic Info" && !isLoading && !error) && (<>
                <BasicInfo
                    handleChange={handleChange}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    phone={phone}
                />
            </>)}
            {(page === "Address" && !isLoading && !error) && (<>
                <GoogleMaps
                    address={address}
                    lat={lat}
                    lng={lng}
                    updateLocation={handleUpdateState}
                    zoom={zoom}
                    googleMapsApiKey={googleMapsApiKey}
                />
            </>)}
            {(page === "Utility Bill" && !isLoading && !error) && (<>
                <UploadFiles
                    bills={bills}
                    handleToggle={handleToggle}
                    confirmHasNoUsage={confirmHasNoUsage}
                    handleUpdateState={handleUpdateState}
                    handleUploadBill={handleUploadBill}
                />
                <CheckboxContainer
                    label="I don't have utility bills"
                    id="confirmNotProvidingUtilityBills"
                    name="confirmNotProvidingUtilityBills"
                    checked={confirmNotProvidingUtilityBills}
                    handleToggle={handleToggle}
                    disabled={!!bills && bills.length > 0}
                />
            </>)}
            {(page === "Usage" && !isLoading && !error) && (<>
                <Usage
                    handleToggle={handleToggle}
                    handleUpdateState={handleUpdateState}
                    hasOnlyYearlyUsage={hasOnlyYearlyUsage}
                    yearlyUsage={yearlyUsage}
                    confirmHasNoUsage={confirmHasNoUsage}
                    januaryUsage={januaryUsage}
                    februaryUsage={februaryUsage}
                    marchUsage={marchUsage}
                    aprilUsage={aprilUsage}
                    mayUsage={mayUsage}
                    juneUsage={juneUsage}
                    julyUsage={julyUsage}
                    augustUsage={augustUsage}
                    septemberUsage={septemberUsage}
                    octoberUsage={octoberUsage}
                    novemberUsage={novemberUsage}
                    decemberUsage={decemberUsage}
                />
            </>)}
            {(page === "Appointment" && !isLoading && !error) && (<>
                <AppointmentInfo
                    appointmentDateTime={appointmentDateTime}
                    isAppointmentSetInternally={isAppointmentSetInternally}
                    handleToggle={handleToggle}
                    handleUpdateState={handleUpdateState}
                />
            </>)}
        </ContainerWithNavbar>
    )
}
