import React from 'react'
import StyledInput from './StyledInput';

export default function BasicInfo(props) {
    const {
        handleChange,
        firstName,
        lastName,
        email,
        phone
    } = props;
    return (
        <div className='p-1 ubuntu'>
            <p className="mt-3 mb-3 p-0-4">Please provide basic customer information</p>
            <StyledInput
                label="First Name"
                name="firstName"
                id="firstName"
                placeholder="Enter first name..."
                type="text"
                value={firstName}
                onChange={handleChange}
                mb
            />
            <StyledInput
                label="Last Name"
                name="lastName"
                id="lastName"
                placeholder="Enter last name..."
                type="text"
                value={lastName}
                onChange={handleChange}
                mb
            />
            <StyledInput
                label="Email"
                name="email"
                id="email"
                placeholder="Enter email..."
                type="email"
                value={email}
                onChange={handleChange}
                mb
            />
            <StyledInput
                label="Phone"
                name="phone"
                id="phone"
                placeholder="Enter phone..."
                type="tel"
                value={phone}
                onChange={handleChange}
                mb
            />
        </div>
    )
}
