import React from 'react';

export default function NavbarNewContact(props) {
    const {
        title,
        handleNext,
        handleBack,
        disabledNext,
        disabledBack,
        labelNext,
        labelBack,
        mb
    } = props;
    return (
        <div className="w-100 flex-between background-2 h-4 p-0-2">
            <button
                className="ubuntu button-variant-2 text-2 color-12"
                onClick={handleBack}
                disabled={disabledBack}
            >
                {labelBack}
            </button>
            <p className="text-2 bold">{title}</p>
            <button
                className={`button-variant-2 text-2 bold color-4 ${disabledNext ? "button-disabled" : ""}`}
                onClick={handleNext}
                disabled={disabledNext}
            >
                {labelNext}
            </button>
        </div>
    )
}
