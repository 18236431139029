import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import ContainerWithNavbar from './ContainerWithNavbar';
import { useAuth } from '../contexts/AuthContext';
import SimpleNavbar from '../components/SimpleNavbar';
import FileContainer from '../components/FileContainer';
import {
  getFirstNameFromContact,
  getLastNameFromContact,
  getStageNameFromContact,
  getStatusFromContact,
  getExpectedDaysInStageFromContact,
  getActualDaysInStageFromContact,
  getEmailFromContact,
  getPhoneFromContact,
  getAddressFromContact,
  getYearlyUsageFromContact,
  getUsageByMonthFromContact,
  getSumOfMonthlyUsage,
  getAppointmentFromContact,
  getBillsFromContact
} from '../services/contacts';
import PersonIcon from '@mui/icons-material/Person';
import IconInCircle from '../components/IconInCircle';
import {
  getClassNameFromStage,
  getClassNameFromStatus,
  getClassNameForDaysSpentInStage
} from '../services/styles';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactPage() {
  const params = useParams();
  const contactId = !!params && params.contactId;
  const initialState = {
    isLoading: true,
    error: '',
    contact: ''
  }
  const [state, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      console.log({contactId});
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            setState({
              ...state,
              isLoading: false,
              error: '',
              contact: res.data.contact
            });
            console.log(res.data);
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  },[contactId]);

  function handleBack(e) {
    e.preventDefault();
    console.log('handleBack');
    navigate('/contacts');
  }

  function handleNavigate(e, route) {
    e.preventDefault();
    navigate(route);
  }

  const {
    error,
    isLoading,
    contact
  } = state;

  const firstName = getFirstNameFromContact(contact);
  const lastName = getLastNameFromContact(contact);
  const email = getEmailFromContact(contact);
  const phone = getPhoneFromContact(contact);
  const address = getAddressFromContact(contact);
  const status = getStatusFromContact(contact);
  const stage = getStageNameFromContact(contact);
  const expectedDaysInThisStage = getExpectedDaysInStageFromContact(contact);
  const actualDaysInThisStage = getActualDaysInStageFromContact(contact);
  const yearlyUsage = getYearlyUsageFromContact(contact, true);
  const januaryUsage = getUsageByMonthFromContact(contact, 'january', true);
  const februaryUsage = getUsageByMonthFromContact(contact, 'february', true);
  const marchUsage = getUsageByMonthFromContact(contact, 'march', true);
  const aprilUsage = getUsageByMonthFromContact(contact, 'april', true);
  const mayUsage = getUsageByMonthFromContact(contact, 'may', true);
  const juneUsage = getUsageByMonthFromContact(contact, 'june', true);
  const julyUsage = getUsageByMonthFromContact(contact, 'july', true);
  const augustUsage = getUsageByMonthFromContact(contact, 'august', true);
  const septemberUsage = getUsageByMonthFromContact(contact, 'september', true);
  const octoberUsage = getUsageByMonthFromContact(contact, 'october', true);
  const novemberUsage = getUsageByMonthFromContact(contact, 'november', true);
  const decemberUsage = getUsageByMonthFromContact(contact, 'december', true);
  const hasMonthlyUsage = !!januaryUsage || !!februaryUsage || !!marchUsage || !!aprilUsage || !!mayUsage || !!juneUsage || !!julyUsage || !!augustUsage || !!septemberUsage || !!octoberUsage || !!novemberUsage || !!decemberUsage;
  const sumOfMonthlyUsage = getSumOfMonthlyUsage(contact);
  const readableAppointment = getAppointmentFromContact(contact, true);
  const bills = getBillsFromContact(contact);

  let statusClasses = '';
  const statusColor = getClassNameFromStatus(status);
  if(!!statusColor) {
      statusClasses += statusColor;
  }
  let stageClasses = '';
  const stageColor = getClassNameFromStage(stage);
  if(!!stageColor) {
    stageClasses += stageColor;
  }

  let actualDaysClasses = 'f-3';
  const daysInStageColor = getClassNameForDaysSpentInStage(expectedDaysInThisStage, actualDaysInThisStage);
  if(!!daysInStageColor) {
    actualDaysClasses += ` ${daysInStageColor}`;
  }


  if(error) {
    return (
      <ContainerWithNavbar>
          <p>We're sorry, there has been an error.  Please reach out to support.</p>
      </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar>
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (contact) {
    return (
      <ContainerWithNavbar>
        <SimpleNavbar
          title="Customer Details"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
        />
        <div className='w-100 p-5 flex-vertical-center'>
          <IconInCircle className="flex-grow-0 circle-icon-container-large" >
            <PersonIcon fontSize='large' />
          </IconInCircle>
            <p className='mt-1 f-1-5'>{firstName} {lastName}</p>
        </div>
        <div className='p-1 ubuntu'>
          <p className='mb-1 flex-between'><span className="ubuntu bold">PROGRESS INFO</span>{!readableAppointment && <button className='button-variant-2 text-2 bold color-4' onClick={(e) => handleNavigate(e, `/contacts/${contactId}/appointment`)} >Set Appointment</button>}</p>
          <div className='grid-2-col w-100'>
            {readableAppointment && (
              <div className='background-4 card grid-col-span-2 text-start fw-light'>
                <div className="flex-between">
                  <p className="bold mb-1">Appointment</p>
                  <button className="button-variant-no-background text-2" onClick={(e) => handleNavigate(e, `/contacts/${contactId}/appointment`)}>
                    Edit
                  </button>
                </div>
                <p>{readableAppointment}</p>
              </div>
            )}
            <div className="card text-start flex-vertical align-items-start">
              <p className="bold mb-1">Current Status</p>
              <p className={statusClasses}>{status}</p>
            </div>
            <div className="card text-start flex-vertical align-items-start">
              <p className="bold mb-1">Stage</p>
              <p className={stageClasses}>{stage}</p>
            </div>
            <div className="card text-start flex-vertical align-items-start">
              <p className="bold mb-1">Expected Days In This Stage</p>
              <p className="f-3">{expectedDaysInThisStage}</p>
            </div>
            <div className="card text-start flex-vertical align-items-start">
              <p className="bold mb-1">Actual Days In This Stage</p>
              <p className={actualDaysClasses}>{actualDaysInThisStage}</p>
            </div>
            <div className="card grid-col-span-2 text-start fw-light">
              <div className="flex-between">
                <p className="bold mb-1">Basic Info</p>
                <button className="button-variant-no-background text-2 color-12" onClick={(e) => handleNavigate(e, `/contacts/${contactId}/basic-info`)}>
                  Edit
                </button>
              </div>
              <p>Email: {email}</p>
              <p>Phone: {phone}</p>
            </div>
            <div className="card grid-col-span-2 text-start fw-light">
              <div className="flex-between">
                <p className="bold mb-1">Address</p>
                <button className="button-variant-no-background text-2 color-12" onClick={(e) => handleNavigate(e, `/contacts/${contactId}/address`)}>
                  Edit
                </button>
              </div>
              <p>{address}</p>
            </div>
            <div className="card grid-col-span-2 text-start fw-light">
              <div className="flex-between">
                <p className="bold mb-1">Utility Bills</p>
                <button className='button-variant-no-background text-2 color-12' onClick={(e) => handleNavigate(e, `/contacts/${contactId}/utility-bills`)}>
                  Edit
                </button>
              </div>
              {bills && bills.length > 0 && bills.map((bill, index) => (
                  <FileContainer
                    key={bill.googleDriveId || bill.temporaryId || `bill-${index}`}
                    bill={bill}
                    classes='p-2-0 mt-1 mb-1 flex-between'
                    hideRemove
                    shortenName
                />
              ))}
              {/* todo handle viewing utility bills here */}
            </div>
            <div className="card grid-col-span-2 text-start fw-light">
              <div className="flex-between">
                <p className="bold mb-1">Usage</p>
                <button className='button-variant-no-background text-2 color-12' onClick={(e) => handleNavigate(e, `/contacts/${contactId}/usage`)}>
                  Edit
                </button>
              </div>
              {yearlyUsage && <p>{yearlyUsage}</p>}
              {(!yearlyUsage && !!hasMonthlyUsage) && <>
                <p>Yearly usage: {sumOfMonthlyUsage}</p>
                <p>January: {januaryUsage}</p>
                <p>February: {februaryUsage}</p>
                <p>March: {marchUsage}</p>
                <p>April: {aprilUsage}</p>
                <p>May: {mayUsage}</p>
                <p>June: {juneUsage}</p>
                <p>July: {julyUsage}</p>
                <p>August: {augustUsage}</p>
                <p>September: {septemberUsage}</p>
                <p>October: {octoberUsage}</p>
                <p>November: {novemberUsage}</p>
                <p>December: {decemberUsage}</p>
              </>}
              {(!yearlyUsage && !hasMonthlyUsage) && <p>No usage</p>}
              
            </div>
          </div>
        </div>
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar>
        <SimpleNavbar
          title="Customer Details"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
        />
        <div className='w-100 h-30vh flex-center'>
          <div>
            <p>Contact not found. Please reach out to support if you believe this was an error.  Thank you!</p>
          </div>
        </div>
      </ContainerWithNavbar>
    )
  }
}
