import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import ContainerWithNavbar from './ContainerWithNavbar';
import { useAuth } from '../contexts/AuthContext';
import SimpleNavbar from '../components/SimpleNavbar';
import {
    getHasOnlyYearlyUsageFromContact,
    getYearlyUsageFromContact,
    getUsageByMonthFromContact,
    getConfirmHasNoUsageFromContact
} from '../services/contacts';
import Usage from '../components/Usage';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactUsagePage() {
  const params = useParams();
  const contactId = !!params && params.contactId;
  const initialState = {
    isLoading: true,
    error: '',
    contact: '',
    hasOnlyYearlyUsage: '',
    yearlyUsage: '',
    confirmHasNoUsage: '',
    januaryUsage: '',
    februaryUsage: '',
    marchUsage: '',
    aprilUsage: '',
    mayUsage: '',
    juneUsage: '',
    julyUsage: '',
    augustUsage: '',
    septemberUsage: '',
    octoberUsage: '',
    novemberUsage: '',
    decemberUsage: ''
  }
  const [state, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            const initialHasOnlyYearlyUsage = getHasOnlyYearlyUsageFromContact(res.data.contact);
            const initialConfirmHasNoUsage = getConfirmHasNoUsageFromContact(res.data.contact);
            const initialYearlyUsage = getYearlyUsageFromContact(res.data.contact);
            const initialJanuaryUsage = getUsageByMonthFromContact(res.data.contact, 'january');
            const initialFebruaryUsage = getUsageByMonthFromContact(res.data.contact, 'february');
            const initialMarchUsage = getUsageByMonthFromContact(res.data.contact, 'march');
            const initialAprilUsage = getUsageByMonthFromContact(res.data.contact, 'april');
            const initialMayUsage = getUsageByMonthFromContact(res.data.contact, 'may');
            const initialJuneUsage = getUsageByMonthFromContact(res.data.contact, 'june');
            const initialJulyUsage = getUsageByMonthFromContact(res.data.contact, 'july');
            const initialAugustUsage = getUsageByMonthFromContact(res.data.contact, 'august');
            const initialSeptemberUsage = getUsageByMonthFromContact(res.data.contact, 'september');
            const initialOctoberUsage = getUsageByMonthFromContact(res.data.contact, 'october');
            const initialNovemberUsage = getUsageByMonthFromContact(res.data.contact, 'november');
            const initialDecemberUsage = getUsageByMonthFromContact(res.data.contact, 'december');
            setState({
              ...state,
              isLoading: false,
              error: '',
              contact: res.data.contact,
              hasOnlyYearlyUsage: initialHasOnlyYearlyUsage,
              confirmHasNoUsage: initialConfirmHasNoUsage,
              yearlyUsage: initialYearlyUsage,
              januaryUsage: initialJanuaryUsage,
              februaryUsage: initialFebruaryUsage,
              marchUsage: initialMarchUsage,
              aprilUsage: initialAprilUsage,
              mayUsage: initialMayUsage,
              juneUsage: initialJuneUsage,
              julyUsage: initialJulyUsage,
              augustUsage: initialAugustUsage,
              septemberUsage: initialSeptemberUsage,
              octoberUsage: initialOctoberUsage,
              novemberUsage: initialNovemberUsage,
              decemberUsage: initialDecemberUsage
            });
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  },[]);

  function handleBack(e) {
    e.preventDefault();
    console.log('handleBack');
    navigate(`/contacts/${contactId}`);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setState({
        ...state,
        isLoading: true
    });
    const dataToSend = {
        hasOnlyYearlyUsage: state.hasOnlyYearlyUsage,
        yearlyUsage: state.yearlyUsage,
        confirmHasNoUsage: state.confirmHasNoUsage,
        januaryUsage: state.januaryUsage,
        februaryUsage: state.februaryUsage,
        marchUsage: state.marchUsage,
        aprilUsage: state.aprilUsage,
        mayUsage: state.mayUsage,
        juneUsage: state.juneUsage,
        julyUsage: state.julyUsage,
        augustUsage: state.augustUsage,
        septemberUsage: state.septemberUsage,
        octoberUsage: state.octoberUsage,
        novemberUsage: state.novemberUsage,
        decemberUsage: state.decemberUsage
    }
    currentUser.getIdTokenResult().then(tokenIdResult => {
        const newToken = !!tokenIdResult && tokenIdResult.token;
        return axios.put(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, dataToSend, {
            headers: {
              'Authorization': `Bearer ` + newToken
            }
        }).then(res => {
            setState({
                ...state,
                isLoading: false
            });
            if(!!res && !!res.data && !!res.data.contact) {
                if(res.data.contact._id) {
                    navigate(`/contacts/${res.data.contact._id}`);
                } else {
                    setState({
                        ...state,
                        isLoading: false,
                        error: true
                    })
                }
            } else {
                setState({
                    ...state,
                    error: true
                })
            }
        }).catch(error => {
            console.log(error);
            Sentry.captureException(error);
            setState({
                ...state,
                isLoading: false,
                error
            })
        });
    });
  }

  function handleUpdateState(data) {
    if(!!data && typeof data === "object") {
        setState({
            ...state,
            ...data,
            hasEdits: true
        });
    }
  }

  function handleToggle(e) {
    if(e.target.name === "hasOnlyYearlyUsage" && !!e.target.checked){
        setState({
            ...state,
            [e.target.name]: !state[e.target.name],
            confirmHasNoUsage: false,
            hasEdits: true
        })
    } else {
        setState({
            ...state,
            [e.target.name]: !state[e.target.name],
            hasEdits: true
        })
    }
}

  const {
    error,
    isLoading,
    contact,
    hasEdits,
    hasOnlyYearlyUsage,
    yearlyUsage,
    confirmHasNoUsage,
    januaryUsage,
    februaryUsage,
    marchUsage,
    aprilUsage,
    mayUsage,
    juneUsage,
    julyUsage,
    augustUsage,
    septemberUsage,
    octoberUsage,
    novemberUsage,
    decemberUsage
  } = state;

  let enableSubmit = false;
  //   todo - fix hasEdits
  if(!!hasEdits) {
    enableSubmit = true;
  }

  if(error) {
    return (
        <ContainerWithNavbar>
            <p>We're sorry, there has been an error.  Please reach out to support.</p>
        </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar>
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (contact) {
    return (
        <ContainerWithNavbar>
        <SimpleNavbar
          title="Usage"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
          labelButtonRight="Submit"
          handleButtonRight={handleSubmit}
          enableButtonRight={enableSubmit}
        />
        <Usage
            handleToggle={handleToggle}
            handleUpdateState={handleUpdateState}
            hasOnlyYearlyUsage={hasOnlyYearlyUsage}
            yearlyUsage={yearlyUsage}
            confirmHasNoUsage={confirmHasNoUsage}
            januaryUsage={januaryUsage}
            februaryUsage={februaryUsage}
            marchUsage={marchUsage}
            aprilUsage={aprilUsage}
            mayUsage={mayUsage}
            juneUsage={juneUsage}
            julyUsage={julyUsage}
            augustUsage={augustUsage}
            septemberUsage={septemberUsage}
            octoberUsage={octoberUsage}
            novemberUsage={novemberUsage}
            decemberUsage={decemberUsage}
        />
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar>
        <SimpleNavbar
          title="Usage"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
        />
        <div className='w-100 h-30vh flex-center'>
          <div>
            <p>Contact not found. Please reach out to support if you believe this was an error.  Thank you!</p>
          </div>
        </div>
      </ContainerWithNavbar>
    )
  }
}
