import React from 'react'

export default function ContainerVerticalFullScreen(props) {
    let classes = "fit-screen flex-vertical align-items-stretch text-center fixed-width-desktop";
    if(!!props.padding) {
        classes += " container-padding"
    }
    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}
