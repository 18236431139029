import React from 'react';
import * as uuid from 'uuid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileContainer from './FileContainer';

export default function UploadFiles(props) {
    const { bills } = props;
    function handleUploadBill(e) {
        let file = '';
        if(!!e.target.files && e.target.files.length > 0 && !!e.target.files[0]) {
            file = e.target.files[0];
            props.handleUploadBill({ file, isLoading: true, name: file.name, temporaryId: uuid.v4() });
        }
    }

    function handleRemoveBill(e, googleDriveId) {
        e.preventDefault();
        props.handleRemoveBill(googleDriveId);
    }

  return (
    <>
    {/* <button
        className="p-2 w-100 ls-0 ubuntu bold flex-between"
        onClick={handleUploadBill}
    >
        <span style={{display:'block'}}><CloudUploadIcon fontSize='large' /></span>
        Upload Bills
        <span></span>
    </button> */}
    {/* todo - style bill upload */}
    <input type="file" onChange={handleUploadBill} />
        {!!bills && bills.length > 0 && bills.map((bill,index) => (
            <FileContainer
                key={bill.lastModified || bill.googleDriveId || index}
                bill={bill}
                handleRemove={handleRemoveBill}
                shortenName
            />
        ))}

    </>
  )
}
