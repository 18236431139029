function getEnvironmentName() {
    return process.env.REACT_APP_ENVIRONMENT;
}

function getImageFromEnvironment() {
    const environment = getEnvironmentName();
    if(!!environment) {
        if(environment === "solare") {
            const SolareMainImage = require('../images/solare/main_image.png');
            return SolareMainImage;
        }
    }
    const DefaultMainImage = require('../images/default/main_image.png');
    return DefaultMainImage;
}

function getSupportEmailFromEnvironment() {
    const environment = getEnvironmentName();
    if(!!environment) {
        if(environment === "solare") {
            return "support@solareenergysolutions.com";
        }
    }
    return "support@resoursify.com";
}

function getTitleFromEnvironment() {
    const environment = getEnvironmentName();
    if(!!environment) {
        if(environment === "solare") {
            return "Solare";
        }
    }
    return "Resoursify";
}

function getNavbarTitleFromEnvironment() {
    const environment = getEnvironmentName();
    if(!!environment) {
        if(environment === "solare") {
            return "SOLARE";
        }
    }
    return "resoursify";
}

function getTitleImageFromEnvironment() {
    const environment = getEnvironmentName();
    if(!!environment) {
        if(environment === "solare") {
            const SolareMainImage = require('../images/solare/title.png');
            return SolareMainImage;
        }
    }
    const DefaultMainImage = require('../images/default/title.png');
    return DefaultMainImage;
}

function getLabelWhoSetsTheAppointment() {
    const title = getTitleFromEnvironment();
    if(!!title) {
        return title + " sets the appointment";
    } else {
        return "ERROR please reach out to support";
    }
}

module.exports = {
    getEnvironmentName,
    getImageFromEnvironment,
    getSupportEmailFromEnvironment,
    getTitleFromEnvironment,
    getNavbarTitleFromEnvironment,
    getTitleImageFromEnvironment,
    getLabelWhoSetsTheAppointment
}
