import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import ContainerWithNavbar from './ContainerWithNavbar';
import { useAuth } from '../contexts/AuthContext';
import SimpleNavbar from '../components/SimpleNavbar';
import {
  getFirstNameFromContact,
  getLastNameFromContact,
  getEmailFromContact,
  getPhoneFromContact
} from '../services/contacts';
import BasicInfo from '../components/BasicInfo';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactBasicInfoPage() {
  const params = useParams();
  const contactId = !!params && params.contactId;
  const initialState = {
    isLoading: true,
    error: '',
    contact: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  }
  const [state, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            const initialFirstName = getFirstNameFromContact(res.data.contact);
            const initialLastName = getLastNameFromContact(res.data.contact);
            const initialEmail = getEmailFromContact(res.data.contact);
            const initialPhone = getPhoneFromContact(res.data.contact);
            setState({
              ...state,
              isLoading: false,
              error: '',
              contact: res.data.contact,
              firstName: initialFirstName,
              lastName: initialLastName,
              email: initialEmail,
              phone: initialPhone
            });
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  },[]);

  function handleBack(e) {
    e.preventDefault();
    console.log('handleBack');
    navigate(`/contacts/${contactId}`);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setState({
        ...state,
        isLoading: true
    });
    const dataToSend = {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phone: state.phone
    }
    currentUser.getIdTokenResult().then(tokenIdResult => {
        const newToken = !!tokenIdResult && tokenIdResult.token;
        return axios.put(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, dataToSend, {
            headers: {
              'Authorization': `Bearer ` + newToken
            }
        }).then(res => {
            setState({
                ...state,
                isLoading: false
            });
            if(!!res && !!res.data && !!res.data.contact) {
                if(res.data.contact._id) {
                    navigate(`/contacts/${res.data.contact._id}`);
                } else {
                    setState({
                        ...state,
                        isLoading: false,
                        error: true
                    })
                }
            } else {
                setState({
                    ...state,
                    error: true
                })
            }
        }).catch(error => {
            console.log(error);
            Sentry.captureException(error);
            setState({
                ...state,
                isLoading: false,
                error
            })
        });
    });
  }

  function handleChange(e) {
    setState({
        ...state,
        hasEdits: true,
        [e.target.name]: e.target.value
    });
  }

  const {
    error,
    isLoading,
    contact,
    firstName,
    lastName,
    phone,
    email,
    hasEdits
  } = state;

  let enableSubmit = false;
  if(!!hasEdits && !!firstName && !!lastName && !!phone && !!email) {
    enableSubmit = true;
  }

  if(error) {
    return (
      <ContainerWithNavbar>
          <p>We're sorry, there has been an error.  Please reach out to support.</p>
      </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar>
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (contact) {
    return (
        <ContainerWithNavbar>
        <SimpleNavbar
          title="Basic Info"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
          labelButtonRight="Submit"
          handleButtonRight={handleSubmit}
          enableButtonRight={enableSubmit}
        />
        <BasicInfo
            handleChange={handleChange}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
        />
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar>
        <SimpleNavbar
          title="Basic Info"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
        />
        <div className='w-100 h-30vh flex-center'>
          <div>
            <p>Contact not found. Please reach out to support if you believe this was an error.  Thank you!</p>
          </div>
        </div>
      </ContainerWithNavbar>
    )
  }
}
