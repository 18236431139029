import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import ContainerWithNavbar from './ContainerWithNavbar';
import { useAuth } from '../contexts/AuthContext';
import SimpleNavbar from '../components/SimpleNavbar';
import {
    getBillsFromContact
} from '../services/contacts';
import NoticeCard from '../components/NoticeCard';
import CheckboxContainer from '../components/CheckboxContainer';
import UploadFiles from '../components/UploadFiles';
import { updateMatchingRecordInArray } from '../utils/arrays';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactUtilityBillsPage() {
  const params = useParams();
  const contactId = !!params && params.contactId;
  const initialState = {
    isLoading: true,
    error: '',
    contact: '',
    bills: [],
    confirmNotProvidingUtilityBills: false
  }
  const [state, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            const initialBills = getBillsFromContact(res.data.contact);
            console.log(res.data.contact);
            setState({
              ...state,
              isLoading: false,
              error: '',
              contact: res.data.contact,
              bills: initialBills || []
            });
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  },[]);

  function handleBack(e) {
    e.preventDefault();
    console.log('handleBack');
    navigate(`/contacts/${contactId}`);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setState({
        ...state,
        isLoading: true
    });
    const dataToSend = {
        bills: state.bills
    }
    currentUser.getIdTokenResult().then(tokenIdResult => {
        const newToken = !!tokenIdResult && tokenIdResult.token;
        return axios.put(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, dataToSend, {
            headers: {
              'Authorization': `Bearer ` + newToken
            }
        }).then(res => {
            setState({
                ...state,
                isLoading: false
            });
            if(!!res && !!res.data && !!res.data.contact) {
                if(res.data.contact._id) {
                    navigate(`/contacts/${res.data.contact._id}`);
                } else {
                    setState({
                        ...state,
                        isLoading: false,
                        error: true
                    })
                }
            } else {
                setState({
                    ...state,
                    error: true
                })
            }
        }).catch(error => {
            console.log(error);
            Sentry.captureException(error);
            setState({
                ...state,
                isLoading: false,
                error
            })
        });
    });
  }

  function handleToggle(e) {
    setState({
        ...state,
        hasEdits: true,
        [e.target.name]: !state[e.target.name]
    });
  }

  const handleUploadBill = (file) => {
    if(!!file) {
      const temporaryId = file.temporaryId;
      setState({
        ...state,
        bills: [
          ...state.bills,
          file
        ]
      });

      currentUser.getIdTokenResult().then(tokenIdResult => {
        const newToken = !!tokenIdResult && tokenIdResult.token;

        const formData = new FormData();
        formData.append("bill", file.file);
        formData.append("contactId", contactId);
        return axios.post(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}/bills`, formData, {
          headers: {
            'Authorization': `Bearer ` + newToken,
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if(!!res && !!res.data) {
              setState(prevState => {
                const bills = prevState.bills;
                const update = {
                  isLoading: false
                }
                if(!!res.data.googleDriveId) {
                  update['googleDriveId'] = res.data.googleDriveId;
                }
                const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, update);
                return ({
                  ...prevState,
                  bills: updatedBills
                });
              });
          } else {
            setState(prevState => {
              const bills = prevState.bills;
              const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
              return ({
                ...prevState,
                bills: updatedBills
              });
            })
          }
        }).catch(error => {
          console.log("error: ");
          console.log(error);
          Sentry.captureException(error);
          setState(prevState => {
            const bills = prevState.bills;
            const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
            return ({
              ...prevState,
              bills: updatedBills
            });
          })
        });
      });
    }
  }

  const handleRemoveBill = (googleDriveId) => {
    if(!!googleDriveId) {
      setState(prevState => {
        const bills = prevState.bills;
        const update = {
          isLoading: true
        }
        const updatedBills = updateMatchingRecordInArray(bills, 'googleDriveId', googleDriveId, update);
        return ({
          ...prevState,
          bills: updatedBills
        });
      });

      currentUser.getIdTokenResult().then(tokenIdResult => {
        const newToken = !!tokenIdResult && tokenIdResult.token;

        return axios.delete(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}/bills/${googleDriveId}`, {
          headers: {
            'Authorization': `Bearer ` + newToken,
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if(!!res && !!res.data) {
              setState(prevState => {
                const bills = prevState.bills;
                const update = {
                  deleted: true
                }
                const updatedBills = updateMatchingRecordInArray(bills, 'googleDriveId', googleDriveId, update);
                return ({
                  ...prevState,
                  isLoading: false,
                  bills: updatedBills
                });
              });
          } else {
            setState(prevState => {
              return ({
                ...prevState,
                isLoading: false
              });
            })
          }
        }).catch(error => {
          console.log("error: ");
          console.log(error);
          Sentry.captureException(error);
          setState(prevState => {
            return ({
              ...prevState,
              isLoading: false
            });
          })
        });
      });
    } else {
      throw Error("Cannot remove bill with missing googleDriveId");
    }
  }

  const {
    error,
    isLoading,
    contact,
    bills
  } = state;

  if(error) {
    return (
        <ContainerWithNavbar>
            <p>We're sorry, there has been an error.  Please reach out to support.</p>
        </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar>
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (contact) {
    return (
        <ContainerWithNavbar>
        <SimpleNavbar
          title="Utility Bill"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
          labelButtonRight="Submit"
          handleButtonRight={handleBack}
          enableButtonRight={true}
        />
        <UploadFiles
          bills={bills}
          handleUploadBill={handleUploadBill}
          handleRemoveBill={handleRemoveBill}
          handleToggle={handleToggle}
        />
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar>
        <SimpleNavbar
          title="Utility Bill"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
        />
        <div className='w-100 h-30vh flex-center'>
          <div>
            <p>Contact not found. Please reach out to support if you believe this was an error.  Thank you!</p>
          </div>
        </div>
      </ContainerWithNavbar>
    )
  }
}
