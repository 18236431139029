function getClassNameFromStage(unformattedStage) {
    let className = '';
    if(!!unformattedStage) {
        let stage = unformattedStage.trim();
        if(!!stage) {
            stage = stage.toLowerCase();
            if(['survey scheduled', 'installed'].indexOf(stage) > -1) {
                className = 'color-8';
            } else if (['not interested', 'dnc'].indexOf(stage) > -1) {
                className = 'color-13';
            }
        }
    }
    return className;
}

function getClassNameFromStatus(unformattedStatus) {
    let className = '';
    if(!!unformattedStatus) {
        let status = unformattedStatus.trim();
        if(!!status) {
            status = status.toLowerCase();
            if(['active'].indexOf(status) > -1) {
                className = 'color-8';
            } else if (['inactive'].indexOf(status) > -1) {
                className = 'color-13';
            }
        }
    }
    return className;
}

function getClassNameForDaysSpentInStage(expectedDaysInThisStage = 0, actualDaysInThisStage = 0) {
    let className = '';
    if(expectedDaysInThisStage === 0 && actualDaysInThisStage === 0) {
        return className;
    } else if(actualDaysInThisStage <= expectedDaysInThisStage) {
        className = 'color-8';
    } else if (actualDaysInThisStage <= expectedDaysInThisStage * 3) {
        className = 'color-4';
    } else {
        className = 'color-13';
    }
    return className;
}

module.exports = {
    getClassNameFromStage,
    getClassNameFromStatus,
    getClassNameForDaysSpentInStage
}
