import React from 'react'
import CheckboxContainer from './CheckboxContainer';
import StyledInput from './StyledInput';

export default function Usage(props) {
    const {
        hasOnlyYearlyUsage,
        confirmHasNoUsage,
        handleToggle,
        handleUpdateState,
        yearlyUsage,
        januaryUsage,
        februaryUsage,
        marchUsage,
        aprilUsage,
        mayUsage,
        juneUsage,
        julyUsage,
        augustUsage,
        septemberUsage,
        octoberUsage,
        novemberUsage,
        decemberUsage
    } = props;

    function handleChange (e) {
        handleUpdateState({
            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <p className="mt-3 mb-3 p-0-4">Providing month by month usage is preferred for accurate presentation to the customer.</p>
            <CheckboxContainer
                label="I only have yearly usage"
                id="hasOnlyYearlyUsage"
                name="hasOnlyYearlyUsage"
                checked={hasOnlyYearlyUsage}
                handleToggle={handleToggle}
                center
            />
            {(!hasOnlyYearlyUsage) && <CheckboxContainer
                label="I don’t have usage"
                id="confirmHasNoUsage"
                name="confirmHasNoUsage"
                checked={confirmHasNoUsage}
                handleToggle={handleToggle}
                center
            />}
            {(hasOnlyYearlyUsage && !confirmHasNoUsage) && (<>
                <StyledInput
                    label="Yearly Usage"
                    name="yearlyUsage"
                    id="yearlyUsage"
                    placeholder="1234 kWh"
                    units="kWh"
                    type="number"
                    value={yearlyUsage}
                    onChange={handleChange}
                    mb
                />
            </>)}
            {(!hasOnlyYearlyUsage && !confirmHasNoUsage) && (<>
                <StyledInput
                    label="January"
                    name="januaryUsage"
                    id="januaryUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={januaryUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="February"
                    name="februaryUsage"
                    id="februaryUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={februaryUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="March"
                    name="marchUsage"
                    id="marchUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={marchUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="April"
                    name="aprilUsage"
                    id="aprilUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={aprilUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="May"
                    name="mayUsage"
                    id="mayUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={mayUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="June"
                    name="juneUsage"
                    id="juneUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={juneUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="July"
                    name="julyUsage"
                    id="julyUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={julyUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="August"
                    name="augustUsage"
                    id="augustUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={augustUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="September"
                    name="septemberUsage"
                    id="septemberUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={septemberUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="October"
                    name="octoberUsage"
                    id="octoberUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={octoberUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="November"
                    name="novemberUsage"
                    id="novemberUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={novemberUsage}
                    onChange={handleChange}
                    mb
                />
                <StyledInput
                    label="December"
                    name="decemberUsage"
                    id="decemberUsage"
                    placeholder="554 kWh"
                    units="kWh"
                    type="number"
                    value={decemberUsage}
                    onChange={handleChange}
                    mb
                />
            </>)}
        </>
    )
}
