import React from 'react';
import {
    getFirstNameFromContact,
    getLastNameFromContact,
    getStageNameFromContact,
    getStatusFromContact
} from '../services/contacts';
import {
    getClassNameFromStage,
    getClassNameFromStatus
} from '../services/styles';
import PersonIcon from '@mui/icons-material/Person';
import IconInCircle from './IconInCircle';

export default function ContactRow(props) {
    const {
        contact,
        handleRedirect,
        mb
    } = props;
    let containerClasses = 'pointer background-3 flex-stretch p-1';
    let statusClasses = '';
    let stageClasses = 'text-2-responsive ';

    const firstName = getFirstNameFromContact(contact);
    const lastName = getLastNameFromContact(contact);

    if(!!mb) {
        containerClasses += ' mb-tiny';
    }

    const status = getStatusFromContact(contact);
    const statusColor = getClassNameFromStatus(status);
    if(!!statusColor) {
        statusClasses += statusColor;
    }

    const stage = getStageNameFromContact(contact);
    const stageColor = getClassNameFromStage(stage);
    if(!!stageColor) {
        stageClasses += stageColor;
    }
  return (
    <div
        className={containerClasses}
        onClick={e => handleRedirect(e, `/contacts/${!!contact && contact._id}`)}
    >
        <IconInCircle className="flex-grow-0 mr-1">
            <PersonIcon fontSize='large' />
        </IconInCircle>
        <div className='flex-between align-items-stretch flex-grow-1 text-2'>
            <div className="flex-vertical align-items-start text-start">
                <p className='text-3-responsive'>{firstName} {lastName}</p>
                <p>Status: <span className={statusClasses}>{status}</span></p>
            </div>
            <div className='flex-vertical-end text-end'>
                <p className={!!stage ? 'mb-tiny' : ''}>Stage</p>
                <p className={stageClasses}>{stage}</p>
            </div>
        </div>
    </div>
  )
}
