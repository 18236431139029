import React from 'react';
import { getNavbarTitleFromEnvironment } from '../services/environment';

export default function NavbarTop(props) {
    const title = getNavbarTitleFromEnvironment();
    return (
        <div className="w-100 flex-between background-2 h-4 p-0-2">
            <p className="ml-2 ubuntu">{props.text || ""}</p>
            <p><span className='text-1 ubuntu'>{title}</span></p>
        </div>
    )
}
