import _ from 'lodash';
import themes from './schema.json';
import { getEnvironmentName } from '../services/environment';

export const useTheme = () => {
  const environment = getEnvironmentName();
  let theme = themes.data.default;
  if(!!environment) {
    if(environment === "solare") {
      theme = themes.data.solare;
    }
  }

  const getFonts = () => {
    const allFonts = _.values(_.mapValues(themes.data, 'font'));
    return allFonts;
  }

  return { theme, getFonts };
};
