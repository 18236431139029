import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import ContainerWithNavbar from './ContainerWithNavbar';
import { useAuth } from '../contexts/AuthContext';
import SimpleNavbar from '../components/SimpleNavbar';
import {
  getAddressFromContact,
  getLatitudeFromContact,
  getLongitudeFromContact
} from '../services/contacts';
import GoogleMaps from '../components/GoogleMaps';
import LoadingIcon from '../components/LoadingIcon';

export default function ContactAddressPage() {
  const params = useParams();
  const contactId = !!params && params.contactId;
  const initialState = {
    isLoading: true,
    error: '',
    contact: '',
    address: '',
    lat: '',
    lng: '',
    googleMapsApiKey: ''
  }
  const [state, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    currentUser.getIdTokenResult().then(tokenIdResult => {
      const newToken = !!tokenIdResult && tokenIdResult.token;
      return axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, {
        headers: {
          'Authorization': `Bearer ` + newToken
        }
      }).then(res => {
        if(!!res && !!res.data) {
            const initialAddress = getAddressFromContact(res.data.contact);
            const initialLat = getLatitudeFromContact(res.data.contact);
            const initialLng = getLongitudeFromContact(res.data.contact);
            setState({
              ...state,
              isLoading: false,
              error: '',
              contact: res.data.contact,
              address: initialAddress,
              lat: initialLat,
              lng: initialLng,
              googleMapsApiKey: res.data.googleMapsApiKey
            });
        } else {
            setState({
                ...state,
                isLoading: false,
                error: true
            })
        }
      }).catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setState({
            ...state,
            isLoading: false,
            error
        })
      });
    });
  },[]);

  function setStateWithObject(data) {
    if(!!data && typeof data === "object") {
        setState({
            ...state,
            ...data,
            hasEdits: true
        });
    }
  }

  function handleBack(e) {
    e.preventDefault();
    console.log('handleBack');
    navigate(`/contacts/${contactId}`);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setState({
        ...state,
        isLoading: true
    });
    const dataToSend = {
        address: state.address,
        lat: state.lat,
        lng: state.lng
    }
    currentUser.getIdTokenResult().then(tokenIdResult => {
        const newToken = !!tokenIdResult && tokenIdResult.token;
        return axios.put(`${process.env.REACT_APP_API_URL}/api/contacts/${contactId}`, dataToSend, {
            headers: {
              'Authorization': `Bearer ` + newToken
            }
        }).then(res => {
            setState({
                ...state,
                isLoading: false
            });
            if(!!res && !!res.data && !!res.data.contact) {
                if(res.data.contact._id) {
                    navigate(`/contacts/${res.data.contact._id}`);
                } else {
                    setState({
                        ...state,
                        isLoading: false,
                        error: true
                    })
                }
            } else {
                setState({
                    ...state,
                    error: true
                })
            }
        }).catch(error => {
            console.log(error);
            Sentry.captureException(error);
            setState({
                ...state,
                isLoading: false,
                error
            })
        });
    });
  }

  const {
    error,
    isLoading,
    contact,
    address,
    lat,
    lng,
    hasEdits,
    googleMapsApiKey
  } = state;

  let enableSubmit = false;
  if(!!hasEdits && !!address && !!lat && !!lng) {
    enableSubmit = true;
  }

  if(error) {
    return (
      <ContainerWithNavbar>
          <p>We're sorry, there has been an error.  Please reach out to support.</p>
      </ContainerWithNavbar>
    )
  } else if (isLoading) {
    return (
      <ContainerWithNavbar>
        <LoadingIcon />
      </ContainerWithNavbar>
    )
  } else if (contact) {
    return (
        <ContainerWithNavbar>
        <SimpleNavbar
          title="Basic Info"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
          labelButtonRight="Submit"
          handleButtonRight={handleSubmit}
          enableButtonRight={enableSubmit}
        />
        <GoogleMaps
            address={address}
            lat={lat && parseFloat(lat)}
            lng={lng && parseFloat(lng)}
            updateLocation={setStateWithObject}
            googleMapsApiKey={googleMapsApiKey}
            zoom={!!lat && !!lng && 23}
        />
      </ContainerWithNavbar>
    )
  } else {
    return (
      <ContainerWithNavbar>
        <SimpleNavbar
          title="Basic Info"
          labelButtonLeft="Back"
          handleButtonLeft={handleBack}
          enableButtonLeft={true}
        />
        <div className='w-100 h-30vh flex-center'>
          <div>
            <p>Contact not found. Please reach out to support if you believe this was an error.  Thank you!</p>
          </div>
        </div>
      </ContainerWithNavbar>
    )
  }
}
