const moment = require("moment");

const stages = [
    {
        name: 'Attempting To Book The Appointment',
        days: '3'
    },
    {
        name: 'Appointment Booked',
        days: '7'
    },
    {
        name: 'Appointment Rescheduled',
        days: '7'
    },
    {
        name: 'Appointment No Show',
        days: 'N/A'
    },
    {
        name: 'Appointment Complete - Hot',
        days: '15'
    },
    {
        name: 'Appointment Complete - Warm',
        days: '30'
    },
    {
        name: 'Appointment Complete - Cold',
        days: 'N/A'
    },
    {
        name: 'Not Interested',
        days: 'N/A'
    },
    {
        name: 'Sold - Contract Signed',
        days: '3'
    },
    {
        name: 'Survey Scheduled',
        days: '7'
    },
    {
        name: 'Waiting For Plan Set',
        days: '7'
    },
    {
        name: 'Waiting For Permit',
        days: '15'
    },
    {
        name: 'Waiting For HOA',
        days: 'N/A'
    },
    {
        name: 'Waiting For Financing',
        days: '7'
    },
    {
        name: 'Install Scheduled',
        days: '7'
    },
    {
        name: 'Install Completed',
        days: 'N/A'
    }
]

export function getFirstNameFromContact(contact) {
    if(!!contact) {
        return contact.firstName;
    }
}

export function getLastNameFromContact(contact) {
    if(!!contact) {
        return contact.lastName;
    }
}

export function getEmailFromContact(contact) {
    if(!!contact) {
        return contact.email;
    }
}

export function getPhoneFromContact(contact) {
    if(!!contact) {
        return contact.phone;
    }
}

export function getAddressFromContact(contact) {
    if(!!contact) {
        return contact.address;
    }
}

export function getLatitudeFromContact(contact) {
    if(!!contact) {
        return contact.lat;
    }
}

export function getLongitudeFromContact(contact) {
    if(!!contact) {
        return contact.lng;
    }
}

export function getStageFromContact(contact) {
    if(!!contact) {
        return contact.stage;
    }
}

export function getStageNameFromContact(contact) {
    if(!!contact) {
        const stage = getStageFromContact(contact);
        if(!!stage) {
            return stage.name;
        }
    }
}

export function getStatusFromContact(contact) {
    if(!!contact) {
        return contact.status;
    }
}

export function getActualDaysInStageFromContact(contact) {
    if(!!contact) {
        const stage = getStageFromContact(contact);
        if(!!stage) {
            const updatedOn = stage.updatedOn;
            if(!!updatedOn) {
                return moment().diff(updatedOn, 'days', false)
            }
        }
    }
}

export function getExpectedDaysInStageFromContact (contact) {
    if(!!contact) {
        const stage = getStageNameFromContact(contact);
        if(!!stage) {
            const matchingStage = stages.find(item => item.name === stage);
            if(!!matchingStage) {
                return matchingStage.days;
            }
        }
    }
    return "N/A";
}

export function getYearlyUsageFromContact(contact, appendUnits) {
    if(!!contact && !!contact.yearlyUsage) {
        if(!!contact.yearlyUsage) {
            if(!!appendUnits) {
                return contact.yearlyUsage + " kWh";
            } else {
                return contact.yearlyUsage;
            }
        }
    }
}

export function getHasOnlyYearlyUsageFromContact(contact) {
    if(!!contact) {
        return contact.hasOnlyYearlyUsage;
    }
}

export function getUsageByMonthFromContact(contact, month, appendUnits) {
    if(!!contact && !!month) {
        const usage = contact[`${month}Usage`];
        if(!!appendUnits) {
            return (usage || 0) + " kWh";
        } else {
            return usage;
        }
    }
}

export function getBillsFromContact(contact) {
    const bills = [];
    if(!!contact) {
        if(!!contact.bills && contact.bills.length > 0) {
            contact.bills.forEach(bill => {
                if(!!bill && !bill.deleted) {
                    bills.push(bill);
                }
            });
        }
    }
    return bills;
}

export function getConfirmHasNoUsageFromContact(contact) {
    if(!!contact) {
        return contact.confirmHasNoUsage;
    }
}

export function getConfirmNotProvidingUtilityBills(contact) {
    if(!!contact) {
        return contact.confirmNotProvidingUtilityBills;
    }
}

export function getArrayOfMonthlyUsage(contact) {
    const arrayOfMonthlyUsage = [];
    if(!!contact) {
        const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
        months.forEach(month => {
            const usage = getUsageByMonthFromContact(contact, month);
            arrayOfMonthlyUsage.push(usage || 0);
        });
    }
    return arrayOfMonthlyUsage;
}

export function getSumOfMonthlyUsage(contact) {
    let sum = 0;
    if(!!contact) {
        const arrayOfMonthlyUsage = getArrayOfMonthlyUsage(contact);
        arrayOfMonthlyUsage.forEach(usage => {
            if(!!usage && typeof usage === "number") {
                sum += usage;
            }
        })
    }
    return sum + " kWh";
}

export function getAppointmentFromContact(contact, format) {
    if(!!contact) {
        const appointment = contact.appointmentDateTime;
        if(!!appointment) {
            if(!!format) {
                return moment(appointment).format("MMMM Do YYYY, h:mm:ss a");
            } else {
                return appointment;
            }
        }
    }
}
