import React from 'react'
import NavbarBottom from '../components/NavbarBottom'
import NavbarTop from '../components/NavbarTop'

export default function ContainerWithNavbar(props) {
  return (
    <div className="responsive-width-100-50 text-center fixed-width-desktop h-min-100vh pb-8">
      <NavbarTop text={props.text} />
      {props.children}
      <NavbarBottom />
    </div>
  )
}
